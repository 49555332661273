import * as React from "react";
import { ArrowRightIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import { useStore } from "../app/store/store";
import Layout from "../components/global/layout";
import Navbar from "../components/global/navbar";
import PageTitle from "../components/global/page-title";
import Warning from "../components/Warning";

const InfoBlock = ({ label, details }) => {
  return (
    <div className="bg-gray-400 bg-opacity-5 rounded-md py-2 px-2 w-1/2 flex-col align-middle">
      <span className="block font-bold font-display text-lg uppercase text-primary-gray">
        {label}
      </span>
      {details && (
        <span className="block font-sans text-gray-400 text-justify text-sm font-normal">
          {details}
        </span>
      )}
    </div>
  );
};

const KeyValueDetail = ({ value = "" }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="w-30 font-display font-normal">{value}</div>
    </div>
  );
};

const hasResults = (result) => {
  return Object.keys(result).length > 0 && !result.error;
};

const DetallesDeLaCotizacion = () => {
  const [globalState] = useStore();
  const result = globalState.quote.result;
  const { costo, estado_destino: destination, estado_origen: origin } = result;
  return (
    <>
      <Layout>
        <Navbar />
        <PageTitle>Detalles de la cotización</PageTitle>
        {hasResults(result) && (
          <div className="md:w-9/12 md:mx-auto md:my-20 rounded-2xl md:shadow-md md:py-12 overflow-hidden">
            <div className="flex-col space-y-9 mt-8 md:px-12 lg:px-24">
              <div className="flex justify-between items-center">
                <InfoBlock label="Origen" details={origin} />
                <ChevronRightIcon className="w-5 h-5" />
                <InfoBlock label="Destino" details={destination} />
              </div>
              <div className="flex-col text-center font-display font-bold">
                <p className="text-primary-dark-green text-base mb-4">
                  Costo del envío:
                </p>
                <p className="uppercase text-primary-light-green text-4xl">
                  <span>$ {parseFloat(costo).toFixed(2) || costo} MX</span>
                </p>
              </div>
              <div className="space-y-3">
                <KeyValueDetail value="El precio ya incluye IVA" />
              </div>
              <div className="flex justify-center">
                <Link
                  to="/realizar-envio"
                  className="btn-shape text-white bg-primary-light-red hover:bg-opacity-90"
                >
                  Realizar envío <ArrowRightIcon className="w-4 h-4" />
                </Link>
              </div>
            </div>
          </div>
        )}
        {!hasResults(result) && <Warning />}
      </Layout>
    </>
  );
};

export default DetallesDeLaCotizacion;
